@import '../../styles/base/variables';

.product-card-form {
  border-radius: 20px;
  border: 2px solid #ededed;

  .product-card-header {
    background: #ededed;
    height: 100px;
    padding: 20px;
    border-radius: 15px 15px 0px 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .product-name {
      font-size: 28px;
      color: $crefaz-blue;
    }

    .title-buttons {
      display: flex;

      .add-button{
        &:disabled{
          color: #263659;
        }
      }

      .x-button {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 14px;
        margin: 0 10px 0 16px;
        font: normal 600 17px/19.5px sans-serif;
        border-radius: 5px;
        color: #263659;
        outline: none;
        border: 2px solid #263659;

        &:hover {
          border: 2px solid #2ea9fb;
        }
      }
    }
  }

  .product-card-content {
    padding: 20px;
  }
}

@media (max-width: 581px) {
  .product-card-form {
    .product-card-header {
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
