.link-situacao-proposta,
.label-situacao-proposta {
    display: inline-block;
    width: 100%;
    position: relative;

    &.link-disabled{
        .border{
            display: none !important;
        }
    }
    .border {
        position: absolute;
        filter: brightness(80%);
        z-index: 95;
        width: 100%;
        border: none !important;
        height: 100%;
        border-radius: 6px;
        display: none;
    }
    .botao-cor {
        letter-spacing: 0.02em;
        padding: 4px;
        white-space: nowrap;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        display: inline-block;
        position: relative;
        z-index: 99;
        color: #fff !important;
        transition: 0.2s;
        line-height: 1.3em;
        margin: 2px 1px;
        font-size: 11px;
        border-radius: 4px;

        &:disabled{
            pointer-events: none !important;
        }

        .btn-situacao {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            div {
                display: inline-block;
            }
        }
        svg {
            height: 14px;
            display: inline-block;
            margin-top: -2px;
            margin-right: 5px;
            path {
                fill: #fff !important;
            }
        }
    }
}

.link-situacao-proposta:hover .border {
    display: block;
}

.label-situacao-proposta {
    cursor: default;
}