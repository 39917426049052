.redefinir-senha-container {
  background-color: #293846;
  width: 100vw;
  min-height: 100vh;
}


.redefinir-senha-brand {
  background-color: #f3f3f4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redefinir-senha-form {
  font: normal 400 16px/21.8px 'Open Sans', sans-serif;
  background-color: #293846;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .login-name{
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .redefinir-senha-item{
    &.ant-form-item-has-error{
      margin-bottom: 0;
    }

    .ant-form-item-explain{
      min-height: 16px;
    }

    &.not-error{
      .ant-form-item-explain{
        display: none;
      }
    }
  
    .redefinir-senha-input {
      background-color: transparent !important;
      border-radius: 0;
      border: 1px solid #7e7e7e;
      color: #fff;
      height: 50px;
      overflow: visible;

      &.error{
        border-color: #ed5555;
        background-color: #ffc2c2 !important;
      }

      &:focus {
        background-color: white !important;
        color: #7e7e7e;
      }
      &:focus ~ .eye-icon {
        svg {
          path {
            fill: #7e7e7e;
          }
        }
      }
    }
  }
}

.redefinir-senha-title {
  margin-bottom: 15px;

  & h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 2px;
  }

  & > .underline {
    border: 1px solid #ffffff;
    width: 35px;
    background-color: #fff;
  }
}

.redefinir-senha-user {
  pointer-events: none;
  background-color: #4f4f4f !important;
  border-radius: 0;
  border: 1px solid #7e7e7e;
  color: #fff !important;
  height: 50px;
}

.redefinir-senha-input:focus,
.redefinir-senha-input:hover {
  border-color: #7e7e7e;
  box-shadow: none;
}

.redefinir-senha-item {
  margin-bottom: 15px;
}

.redefinir-senha-item label {
  color: #fff !important;
  font-size: 13px;
  line-height: 19.5px !important;
  padding-left: 10px;
  position: relative;
  margin-bottom: 2px;
}

.brand-white {
  display: none;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.login-error{
  margin-top: 15px;
}

.dicas {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .titulo-dicas{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
  .dica {
    display: flex;
    gap: 6px;
    align-items: center;

    .icon-check{
      display: flex;
      svg{
        width: 16px;
        height: 16px;
      }
    }
    
    span {
      vertical-align: middle;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
    &.error {
      span {
        color: #ed5565;
      }
      path {
        fill: #ed5565;
      }
    }
    &.success {
      span {
        color: #1ab394;
      }
      path {
        fill: #1ab394;
      }
    }
  }
}

.right-password {
  span {
    color: #1ab394 !important;
  }
  svg {
    path {
      fill: #1ab394;
    }
  }
}

.error-password {
  span {
    color: #ed5565 !important;
  }
  svg {
    path {
      fill: #ed5565;
    }
  }
}

@media (max-width: 992px) {
  .brand {
    display: none;
  }

  .brand-white {
    display: block;
    margin: auto;
    width: 80%;
  }

  .redefinir-senha-brand {
    background-color: #293846 !important;
    height: 30% !important;
    align-items: flex-end !important;
  }
  .redefinir-senha-form {
    height: auto !important;
    padding: 2rem !important;
    align-items: flex-start !important;
  }
}

.redefinir-senha-btn {
  margin-top: 15px;
  height: 50px !important;
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
}
